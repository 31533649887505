<template>
    <div id="UploadCategoryView" class="container-fluid m-0 p-0">
        <div class="row m-0 p-3 justify-content-center">
            <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
                <div class="row m-0 p-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="font-1-2s font-600">Sort Category WEB</p>
                    </div>
                </div>
                <div class="row m-0 p-2">
                    <div class="col-12 ">
                        <b-tabs content-class="mt-2 m-0 p-0">
                            <b-tab title="List Upload Category" active>
                                <TableListCategory :key="tableKey" />
                            </b-tab>
                            <b-tab title="Upload Category">
                                <UploadCategory @uploadSuccess="reloadTable" />
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableListCategory from '../../components/Manage/UploadCategory/TableListCategory.vue'
import UploadCategory from '../../components/Manage/UploadCategory/UploadCategory.vue'


export default {
    name: "UploadCategoryView",
    components: {
        TableListCategory,
        UploadCategory,
    },
    data() {
        return {
            tableKey: 0
        }
    },
    methods: {
        reloadTable() {
            // Incrementing the tableKey to force re-rendering of the table component
            this.tableKey++;
        }
    },
}
</script>
